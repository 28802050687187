const foo = {
  'componentId'         : 0,
  'componentName'       : '国家与地区',
  'componentCode'       : 'ZtCountryAdvance',
  'componentField'      : 'nationality',
  'componentPlaceHolder': null,
  'componentTip'        : '请填写国家与地区',
  'componentSource'     : 1,
  'defaulted'           : 0,
  'required'            : 1,
  'disabled'            : 0,
  'sortNumber'          : 2,
  // 'answer'              : '{"birthday":"1992-05-26","nationality":"CN","residentialImg":"/changzheng/1002110021000193264/9c6350d8d5c44777ac5bef51d4ffaf23.jpg","native":"{\\"districtCode\\":\\"130102000000\\",\\"provinceCode\\":\\"130000\\",\\"cityCode\\":\\"130100000000\\"}","cardType":"0","cardFrontImg":"/changzheng/1002110021000193264/92e15f5dad164e6494fe5e9a51a79a73.jpg","cardNumber":"130102199205260614","cardReverseImg":"/changzheng/1002110021000193264/9cbd76ab51ac41c9a6264c178599ac84.jpg"}',
  'answer'          : JSON.stringify({
    birthday             : '1992-05-26',
    nationality          : 'HK',
    native               : '{"districtCode":"130102000000","provinceCode":"130000","cityCode":"130100000000"}',
    cardType             : '0',
    cardNumber           : '130102199205260614',
    residentialFrontImg  : '/changzheng/1002110021000193264/9c6350d8d5c44777ac5bef51d4ffaf23.jpg',
    residentialReverseImg: '/changzheng/1002110021000193264/9c6350d8d5c44777ac5bef51d4ffaf23.jpg',
    cardFrontImg         : '/changzheng/1002110021000193264/92e15f5dad164e6494fe5e9a51a79a73.jpg',
    cardReverseImg       : '/changzheng/1002110021000193264/9cbd76ab51ac41c9a6264c178599ac84.jpg',
  }),
  'optionList'      : [],
  'combineComponent': null,
}

console.log('【debug-register-data:19】', `foo = `, foo)

const fs = require('fs')
const path = require('path')
fs.writeFileSync(path.resolve(__dirname, './foo.json'), JSON.stringify(foo, null, 2))

